import Button from '@mui/material/Button';
import './Home.css';

function Home() {
    return (
        <div className="page">
          <h1 className="title">RESUME</h1>
          <h2 className="author">By Eileen Catoe</h2>
          <Button className="start" variant="contained" href="/mulch">Start</Button>
        </div>
    )
}

export default Home;