import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Route} from "react-router-dom";
import {Routes} from "react-router-dom";
import Home from "./home/Home";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBar, Button, Toolbar, Typography} from "@mui/material";
import Mulch from "./mulch/Mulch";

const resumeTheme = createTheme({
  palette: {
    primary: {
      main: '#625957',
      dark: '#353535',
    },
    secondary: {
      main: '#6055ea',
      contrastText: '#f7f7f7',
    },
    background: {
      default: 'rgba(26,26,26,0.47)',
      paper: '#ffffff',
    },
    text: {
      primary: '#6b6a6a',
      secondary: 'rgba(255,255,255,0.84)',
      disabled: 'rgba(226,225,225,0.37)',
      hint: 'rgba(210,206,206,0.5)',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={resumeTheme}>
      <BrowserRouter>
        <AppBar>
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              Resume
            </Typography>
            <Button href="/" variant="default">Home</Button>
            <Button href="/mulch" variant="default">Mulch</Button>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/mulch" element={<Mulch/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
